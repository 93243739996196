<script>
export default {
  name: "BVInput",
  props: {
    name: { type: String, required: true },
    type: { type: String, default: "text" },
    label: { type: String, default: "" },
    value: { default: null },
    // hideOptions: una lista de options de base
    options: { type: Array, default: () => [] },
    validations: { type: String, default: "" },
    tooltip: { type: String, default: "" },
    state: { type: String, default: "" },
    observer: { type: Boolean, default: false },
    idx: { type: Number, default: NaN },
    placeholder: { type: String, default: "" },
    errorMessages: { type: Array, default: () => [] },
    help: { type: String, default: "" },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    isRequired() {
      return this.validations.includes("required");
    },
    isDisabled() {
      return this.state.includes("disabled");
    },
    stateStyle() {
      return {
        success: this.state == "success",
        error: this.state == "error",
      };
    },
    readValue() {
      return this.value;
    },
    copyOptions() {
      return this.options.map((option) => {
        if (typeof option == "string") {
          return { value: option, text: option };
        } else {
          return {
            value: option.value || option.id,
            text:
              option.text ||
              option.name ||
              option.label ||
              option.descripcion ||
              option.tipo ||
              option.estado,
          };
        }
      });
    },
  },
  methods: {
    watcher(value) {
      this.$emit("input", { name: this.name, value: value, idx: this.idx });
    },
  },
  silent: true,
};
</script>

<template>
  <div class="outer">
    <label :for="name" class="form-label" v-if="label">
      {{ label }}
      <span v-if="isRequired" class="text-danger">*</span>
      <span
        v-if="tooltip"
        v-b-tooltip.hover.top="tooltip"
        class="border border-primary mx-1 px-2 rounded-circle"
        ><strong><i>i</i> </strong></span
      >
    </label>
    <div class="inner" :class="stateStyle">
      <template v-if="type != 'select'">
        <b-form-input
          @input="watcher"
          :value="readValue"
          :type="type"
          :id="name"
          :required="isRequired"
          :disabled="isDisabled"
          :placeholder="placeholder"
        />
      </template>
      <template v-else>
        <b-form-select
          @input="watcher"
          :value="readValue"
          :options="copyOptions"
          :required="isRequired"
          :disabled="isDisabled"
        >
          <template #first>
            <b-form-select-option selected :value="null" disabled>
              -- Seleccionar --
            </b-form-select-option>
          </template>
        </b-form-select>
      </template>
    </div>
    <div v-if="help">
      <small class="text-white">{{ help }}</small>
    </div>
    <div v-for="error in errorMessages" :key="name + error">
      <small class="text-danger">{{ error }}</small>
    </div>
  </div>
</template>

<style scoped>
.inner {
  transition: 0.4s;
  border-radius: 13px;
}
.inner.success {
  input {
    border-color: #1a5cff;
  }
}
.inner.error {
  input {
    border-color: rgb(242, 19, 67);
  }
}
</style>
